/*** ZERO CARBON New Design - Awais Abbas ***/
:root {
    --orange: #EF4323;
}

* {}

body,
.mat-typography {
    font: 14px/1.46 "Poppins", Arial, sans-serif;
    margin: 0;
    color: #161616;
    background: #FFF9E6;

    a {
        color: var(--orange);

        &:hover {
            text-decoration: underline;
        }
    }

    .text-12 {
        font-size: 12px;
        font-weight: 300;

        a {
            font-weight: 500;
        }
    }

    .text-16 {
        font-size: 16px;

        @media (max-width: 1199px) {
            font-size: 14px;
        }

    }

    .mb-20 {
        margin-bottom: 20px;
    }

    .mb-40 {
        margin-bottom: 40px;

        @media (max-width: 1199px) {
            margin-bottom: 20px;
        }
    }
}

.mat-typography,
body {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: "Poppins", Arial, sans-serif;
        font-weight: 700;
        line-height: 1.17;

        a {
            color: inherit;
        }
    }

    .form-group {
        padding: 0 0 20px;
        margin: 0;
    }

    label {
        display: block;
        font-size: 16px;
        color: #161616;
        margin: 0 0 4px;
        font-family: "Poppins", Arial, sans-serif;
        padding: 0;

        @media (max-width: 1599px) {
            font-size: 14px;
        }
    }

    .form-control {
        width: 100%;
        border: 1px solid #6d6d6d;
        font-family: "Poppins", Arial, sans-serif;
        border-radius: 4px;
        height: 52px;
        padding: 17px 12px;
        font-size: 12px;
        color: #161616;
        line-height: 16px;
        font-weight: 300;

    }

    .btnPrimary {
        -webkit-appearance: none;
        background: var(--orange);
        font-size: 20px;
        line-height: 30px;
        border: 0;
        font-family: "Poppins", Arial, sans-serif;
        color: #fff;
        padding: 16px 15px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
        display: inline-block;
        vertical-align: top;
        border-radius: 4px;

        &:hover {
            background: darken(#EF4323, 10%);
        }
    }
}

@media (min-width: 768px) {
    .main-content {
        position: relative;
        overflow: hidden;
        margin-left: 300px;
        width: calc(100% - 300px);
        transition: all 0.3s ease-in-out;
    }

    #page-topbar,
    .footer {
        left: 300px;
        transition: left 0.3s ease-in-out;
    }
}

.page-content {
    padding: 70px 0 40px !important;
}

.search-form {
    overflow: hidden;
    height: 40px;
    display: flex;
    border-radius: 3px;
    width: 400px;
    background: #fff;
    border: 1px solid rgba(#707070, .2);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);

    @media (max-width:1023px) {
        width: 250px;
    }

    .btn-search {
        width: 50px;
        height: 40px;
        padding: 0;
        color: #fff;
        background: #FFBF00;
        border: 0;
        display: flex;
        align-items: center;
        box-shadow: none;
        outline: none;
        justify-content: center;
        flex-shrink: 0;
    }

    input {
        flex-grow: 1;
        overflow: hidden;
        position: relative;
        border: 0;
        padding: 5px 15px;
        font-size: 12px;
        height: 40px;
        box-shadow: none;
    }

}

.breadcrumb-main {
    display: flex;
    align-items: center;
    padding: 10px 16px 20px;
    min-height: 53px;

    @media (max-width: 1599px) {
        padding-left: 8px;
        padding-right: 8px;
    }

    .breadcrumb {
        flex-grow: 1;
        background: none;
        margin: 0;
        padding: 0;
        position: fixed;
        background: #FFF9E6;
        z-index: 99;
        right: 0;
        top: 60px;
        left: 300px;
        padding: 18px 30px;
        justify-content: center;
        transition: all 0.3s ease-in-out;

        .sidebar-active & {
            left: 0;
        }

        li {
            display: flex;
            align-items: center;
            font-size: 16px;
            letter-spacing: 0.5px;
            color: #000;

            a {
                display: block;
                font-size: 16px;
                color: #000;
                font-family: "Poppins";

                @media (max-width: 1599px) {
                    font-size: 14px;
                }
            }

            &:after {
                font-family: 'font awesome 5 free';
                content: "\f054";
                font-weight: 900;
                margin: 0 40px;

                @media (max-width: 1599px) {
                    margin: 0 20px;
                }
            }

            &.skip-arrow {
                &:after {
                    display: none;
                }
            }
            &.separator {
                margin: 0 25px; 
              }

        }
    }

    .search-holder {
        display: flex;
        align-items: center;

        .filter-select {
            margin: 0 15px 0 0;
            width: 110px;

            select {
                width: 100%;
                padding: 4px 10px;
            }
        }
    }
}

.contents-main {
    padding: 0 16px 40px;

    @media (max-width: 1599px) {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.card-box-frame {
    position: relative;
}

.card-box {
    background: #fff;
    box-shadow: 0 3px 6px 0 rgba(#000, .16);
    border-radius: 4px;
    margin: 0 0 20px;
    overflow: hidden;
    min-height: 231px;
    position: relative;

    .card-header {
        background: #FB8C04;
        padding: 12px 20px;
        box-shadow: 0 3px 6px 0 rgba(#000, .16);


        &.card-header-tabs {
            padding: 0 !important;
            display: block;
            height: auto !important;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                justify-content: center;
                display: flex;

                li {
                    padding: 0 2%;
                    flex-shrink: 0;

                    a {
                        display: block;
                        font-size: 14px;
                        line-height: 20px;
                        text-transform: uppercase;
                        font-weight: 400;
                        padding: 12px;
                        color: #fff;
                        cursor: pointer;
                        border-bottom: 2px solid transparent;

                        &:hover,
                        &.active {
                            border-bottom-color: #000;
                        }
                    }
                }
            }
        }

        h3 {
            font-size: 16px;
            font-weight: 400;
            margin: 0;
            line-height: 20px;
            color: #fff;
        }
    }

    .card-content {
        padding: 30px 20px;

        h3 {
            color: #333334;
            font-size: 22px;
            font-weight: 400;
            margin: 0 0 10px;
        }

        .ico-bottom {
            position: absolute;
            right: 20px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 30px;
            background: #FFE2DC;
        }
    }

    .status-box {
        position: relative;
        margin: 0 0 10px;
        letter-spacing: .4px;

        h4 {
            font-size: 16px;
            font-weight: 400;
            margin: 0 0 8px;
        }

        .status-holder {
            display: flex;
            padding: 0 20px;
            align-items: center;
            justify-content: space-between;
        }

        .box-content {
            flex-grow: 1;
            overflow: hidden;
        }

        .color-wheel {
            flex-shrink: 0;
            margin: 0 0 0 15px;

            &:first-child {
                margin: 0 15px 0 0;
            }
        }

        .bullet-list {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 0 0 8px;
                display: flex;
                align-items: center;

                &:last-child {
                    padding: 0;
                }
            }

            .ico {
                border-radius: 50%;
                background: #FFACAC;
                width: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                flex-shrink: 0;
                margin: 0 15px 0 0;
            }

            .bullet {
                border: 2px solid #C4FFC4;
                background: #41BA41;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                flex-shrink: 0;
                margin: 0 15px 0 0;
            }
        }
    }

    &.add-styles {
        min-height: inherit;

        .card-header {
            @media (max-width: 1439px) {
                padding-left: 15px !important;
                padding-right: 15px !important;
            }

            h3 {
                @media (max-width: 1439px) {
                    font-size: 13px;
                }
            }
        }

        .card-content {
            display: flex;
            align-items: center;
            min-height: 103px;
            padding-left: 15px;
            padding-right: 15px;

            @media (max-width: 1439px) {
                padding-left: 10px;
                padding-right: 10px;
            }

            h3 {
                font-size: 16px;
                flex-grow: 1;
                min-width: 0;
                padding-right: 10px;
                margin: 0;
                
                @media (max-width: 1439px) {
                    font-size: 14px;
                    padding-right: 5px;
                }
            }

            .ico-bottom {
                position: static;
                flex-shrink: 0;
                margin: -4px 0 -3px;
                width: 40px;
                height: 40px;

                svg {
                    transform: scale(0.75);
                }
            }

            .status-box {
                width: 100%;
            }
        }

        .bullet-list {
            min-width: 100px;
        }


        .graph-holder {
            position: relative;
            margin-right: -15px;

            @media (max-width: 1439px) {
                margin-right: -20px;
                margin-left: -15px;
            }
        }

        .ngx-charts-outer {
            position: relative;
            left: 50%;
            transform: translateX(-50%);

            // @media (max-width: 1439px) {
            //     width: 150px !important;
            //     height: 150px !important;
            // }

            // svg {
            //     @media (max-width: 1439px) {
            //         width: 150px !important;
            //         height: 150px !important;
            //     }
            // }
        }
    }
}


.color-wheel {
    width: 170px;
    height: 170px;
    position: relative;
    border-radius: 50%;


    background: conic-gradient(#41BA41 30deg, #41BA41 210deg, #fff 210deg, white 212deg, #FA2A2D 212deg, #FA2A2D 270deg, #fff 270deg, #fff 272deg, #999999 272deg, #999999 358deg, #fff 358deg, #fff 360deg);

    &.color-wheel-2 {
        background: conic-gradient(#FFBF00 0deg, #FFBF00 350deg, #fff 350deg, white 352deg, #4FAFF5 352deg, #4FAFF5 358deg, #fff 358deg, #fff 360deg);
    }

    .text-box {
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        z-index: 50;

        .holder {
            width: 100%;
        }

        strong {
            display: block;
            font-size: 30px;
            font-weight: 400;
        }
    }
}

.color-wheel::after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    background: white;

}

.map-holder {
    position: relative;

    img {
        width: 100%;
        display: block;
        height: auto;
    }
}

.table-wrap {
    box-shadow: 0 3px 6px rgba(#000, .16);
    background: #fff;
    position: relative;

    &.no-shadow {
        box-shadow: none !important;
        border: 0 !important;
        .table-main, table {
            border: 0 !important;
            box-shadow: none !important;
        }
    }
}

.table-main {
    position: relative;
    overflow-x: auto;

    .table-data {
        width: 100%;

        &.no-bg {
           box-shadow: none !important;

           body {
            background: transparent;
           }

            th, tr, td {
                background: #fff !important;
                border: 0 !important;
            }
        }

        span {
            display: block;
        }

        th {
            background: #FB8C04;
            color: #fff;
            text-align: left;
            font-weight: 500;
            white-space: nowrap;
            text-transform: uppercase;

            &.has-nowrao {
                white-space: normal;
            }
        }

        th,
        td {
            padding: 10px 16px !important;
            font-size: 14px;
            line-height: 17px;
            border-color: #C2C2C2;

            &.nowrap {
                white-space: nowrap;
            }

            &.chart {
                padding-bottom: 0 !important;
                vertical-align: bottom !important;
            }
        }
    }

    .status-circle {
        border: 5px solid #FFBCCC;
        background: #E9093F;
        width: 41px;
        height: 41px;
        border-radius: 50%;
        color: #fff;
        font-size: 12px;
        line-height: 33px;
        text-align: center;
        font-weight: 700;
        margin: 0 auto;

    }

    .text-notify {
        color: #D90F00;
        font-weight: 700;
    }

    .text-notify2 {
        color: #F2CA01;
        font-weight: 700;
    }

    .square-status {
        width: 24px;
        height: 24px;
        color: #fff;
        background: #00D54E;
        text-align: center;
        font-weight: 700;
        margin: 0 4px;
        line-height: 24px;
        display: inline-block !important;

        @media (max-width: 1599px) {
            margin: 0 2px;
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-size: 10px !important;
        }

        &.disabled {
            opacity: .5;
        }
    }

    &.stripped {
        border: 0;

        text-align: left;
        width: 100%;

        tr {
            border: 0 !important;
        }

        td,
        th {
            border: 0 !important;
            padding: 7px 8px !important;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            height: auto !important;

            @media (max-width: 1599px) {
                font-size: 10px;
                padding: 7px 6px !important;
            }
        }

        th {
            font-size: 14px;
            color: #F15F12;
            font-weight: 400;
            padding: 12px 8px !important;
            background: none;

            @media (max-width: 1599px) {
                font-size: 11px;
                padding: 12px 6px !important;
            }
        }

        tbody tr:nth-child(odd) {
            background: #F2F2F2;
        }
    }
}

.table-meta {
    display: flex;
    align-items: center;
    padding: 60px 30px;
    justify-content: space-between;

    @media (max-width: 1599px) {
        padding: 30px 15px;
    }

    select {
        width: 60px;
        font-size: 14px;
        padding: 0 3px !important;
        height: 20px;
        flex-shrink: 0;
        line-height: 20px;
        margin: 0 8px;
    }

    .entries {
        display: flex;
        align-items: center;
        color: #676A6C;
        font-size: 14px;

    }
}

.table-pagination {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;

        li {
            padding: 0 6px;

            a {
                min-width: 26px;
                height: 26px;
                color: #676A6C;
                text-align: center;
                display: block;
                padding: 0 5px;
                font-weight: 500;
                background: #F1F5F9;
                border-radius: 3px;
                font-size: 14px;
                line-height: 26px;
                cursor: pointer;

                &.no-bg {
                    background: none;
                }
                &.init-bg {
                    background: #ffe36e; 
                }
                &:hover,
                &.active {
                    background: #FFBF00;
                    color: #fff;
                }
                &.disabled {
                    pointer-events: none; 
                    opacity: 0.5; 
                    cursor: not-allowed;
                }
            }
        }
    }
}

.card-graphical {
    display: flex;
    padding: 0 8%;
    justify-content: space-between;

    .flow-graph {
        flex-grow: 1;
        max-width: 645px;
        position: relative;

        img {
            width: 100%;
            position: relative;
            z-index: 50;
        }

        .graph-area {
            padding: 40px 0 0;
            overflow: hidden;

            .text-box {
                position: absolute;
                left: 24%;
                text-align: center;
                top: 24%;

                &.reverseable {
                    i {
                        animation: animate2 2s both infinite;
                    }
                }

                i {
                    text-align: center;
                    margin: 0 0 10px;
                    display: block;
                    animation: animate 2s both infinite;

                }

                span {
                    span {
                        color: #EF4323;
                    }
                }
            }
        }
    }

    .status-box {
        flex-shrink: 0;
        width: 28%;

        .status-holder {
            padding: 40px 0 0;

        }

        .box-content {
            flex-grow: 0;
        }

        .bullet-list {
            li:not(:last-child) {
                margin-bottom: 25px;
            }
        }
    }
}

.card-plant {
    .card-content {
        padding: 40px 15px;
    }

    .tab-links {
        margin: 0 0 10px;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: center;

        li {
            position: relative;

            a {
                display: block;
                border: 1px solid #D6DBE2;
                text-align: center;
                font-size: 16px;
                line-height: 20px;
                min-width: 180px;
                padding: 10px 20px;
                box-shadow: 0 2px 4px 0 rgba(#000, .16);
                color: #D6DBE2;
                cursor: pointer;

                &.active,
                &:hover {
                    background: #FFBF00;
                    color: #fff;
                }
            }
        }
    }
    .text-base {
        display: flex;
        justify-content:center;
        padding: 25px 0 0; 
        span {
            padding: 0 20px;
        }
    }
}

.box-power {
    position: relative;
    text-align: center;
    .box-circle {
        width: 158px;
        height: 158px;
        border-radius: 50%;
        background: #F15F12;
        color: #fff;
        display: flex;
        align-items: center;
        margin: 0 auto;
        .box-holder {
            width: 100%;
        }
        h3 {
            font-size: 24px;
            color: #fff;
            margin: 0 0 5px;
            font-weight: 400;
        }
    }
    .list-texts {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-flow: row wrap;
        li {
            width: 50%;
            padding: 20px 0 0;
            &:first-child {
                width: 100%;
            }
        }
        span {
            display: block;
            margin: 0 0 3px;
            &:last-child {
                margin: 0;
                color: #F15F12;
            }
        }
    }
}

.arrow-bar {
    display: flex;
    align-items: center;
    padding: 20px 0; 
    .btn-arrow {
        margin: 0 8px;
        flex-shrink: 0;
        
    }
    .border {
        display: block;
        height: 1px;
        flex-grow: 1;
        background: #BBBBBB;
    }
}

.card-plugins {
    margin-top: -10px;
}

.weather-plugin {
    display: flex;
    align-items: flex-start;
    // margin: 0 0 20px;
    justify-content: space-between;
    .plugin-holder {
        width: 45%;

        &.right {
            width: 50%;
        }
    }

    .plugin-holder-wrap {
        position: relative;
        max-width: 190px;
        min-width: 190px;
        margin-left: -15px;
        margin-top: -15px;

        .power-generation-gauge {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }

        .size-text {
            border-bottom: 2px solid #F2CA01;
        }
    }

    .text-box {
        overflow: hidden;
    }
}
.time-wise-graph{
    margin-bottom: -23px;
}

.generation-list {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: -9px;
    .list-holder {
        width: 47%;
        &:nth-child(2) {
            ul strong {
                background: #FFF3CE;
            }
        }
    }
    .ico {
        margin: 0 0 10px
    }
    ul {
        margin: 0;
        padding: 0;
        font-size: 10px;
        
        list-style: none;
        li {
            margin: 0 0 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .text {
            width: 50%;
        }
        strong {
            flex-shrink: 0;
            background: #FFECE9;
            min-width: 80px;
            text-align: center;
            border-radius: 50px;
            padding: 5px;
            span {
                color: #EF4323;
            }
        }
    }
}

.heatmap {
    position: relative;
    .heatmap-head {
        border: 0.5px solid #D6DBE2;
        display: flex;
        flex-flow: row wrap;
        margin: 0 0 20px;
        font-size: 12px;
        span {
            width: 50%;
            border: .5px solid #D6DBE2;
            padding: 4px 10px;
            text-align: center;
            box-shadow: 0  2px 2px 0 rgba(0, 0, 0, 0.06);

        }
    }
    .heatboxes {
        margin: -4px -2px 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        .heatbox {
            width: 27px;
            height: 27px; 
            margin: 4px 2px 0; 
            background: #00D54E;
        }
    }
}
.plugin {
    img {
        display: block;
        max-width: 100%;
        height: auto;
    }
}
.benefits-list {
    
    ul {
        margin: 0;
        padding: 20px 0 70px;
        display: flex;
        justify-content: center;
        list-style: none;
        flex-flow: row wrap;
        li {
            width: 50%;
            padding: 0 15px 25px;
            display: flex;
            align-items: center;
            .ico {
                flex-shrink: 0;
                margin: 0 15px 0 0;
            }
            .text-box {
                flex-grow: 1;
                overflow: hidden;
                font-size: 10px;
                p {
                    display: block;
                    margin: 0 0 10px;
                }
            }
            .number {
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                strong {
                    font-weight: 400;
                    color: #EF4323;
                    font-size: 14px;
                    margin: 0 10px 0 0
                }
            }
        }
    }
    .benefit-spans {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        span {
            display: flex; 
            align-items: center;
            font-size: 10px;
            padding: 0 10px;
            i {
                width: 8px;
                height: 8px;
                margin: 0 5px 0 0;
            }
        }
    }
}
.weather-teller {
    .weather-holder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 10px;
        img {
            flex-shrink: 0;

            &.image-weather {
                width: 75px;
                height: auto;
            }
        }
        .weather-text {
            max-width: 60%;
            strong {
                display: block;
                font-size: 24px;
                color: #000;
                line-height: 1.2;
            }
            .text-12 {
                letter-spacing: 2px;
            }
        }
    }
    .daytime {
        display: flex;
        justify-content: space-between;
        font-size: 10px;
        .box {
            flex-shrink: 0;
            max-width: 50%;
            text-align: center;
        }
        i {
            display: block;
            margin: 0 0 5px;
        }
        .text {
            display: block;
        }
        .time {
            display: block;
            color: #000;
            letter-spacing: 2px;
        }
    }
}
@keyframes animate {
    0% {
        transform: translateX(-300%);
        opacity: 1
    }

    95% {
        opacity: 1
    }

    99% {
        opacity: 0
    }

    100% {
        transform: translateX(305%);
    }
}

@keyframes animate2 {
    0% {
        transform: translateX(300%);
    }


    100% {
        transform: translateX(-305%);
    }
}


.tabMonthYear {
    position: absolute;
    top: 56px;
    left: 50%;
    margin-left: -100px;
}
.card-header:first-child {
    border-radius: 0px;
}
.day_my_btn_vt {
    /* width: 302px; */
    justify-content: center;
    display: flex;
    background: none;
    border: 1px solid #ffffff;
    float: right;
    /* position: absolute; */
    right: 18px;
    /* margin-top: -161px; */
    z-index: 99;
    border-radius: 2px;
    overflow: hidden;
    margin-bottom: 10px;
}
.card-header, .card-title { 
    font-weight: normal !important;
    font-size: .68rem !important;
}
.noRecordFound {
    height: 304px;
    margin: 30px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
}
.day_my_btn_vt .day_bt_vt.active {
    background: #7cb305;
    color: #ffffff;
}
.month_bt_vt.active {
    background: #7cb305;
    color: #ffffff;
}
.month_bt_vt.active {
    background: #7cb305;
    color: #ffffff;
}
.day_my_btn_vt .day_bt_vt {
    background: none;
    width: auto;
    justify-content: center;
    border-radius: 2px;
    line-height: 26px;
    color: #9a9999;
    margin: 0;
    border: none;
    font-size: 10px;
    text-transform: capitalize;
}

.month_bt_vt {
    width: auto;
    justify-content: center;
    border-radius: 2px;
    line-height: 26px;
    color: #9a9999;
    margin: 0;
    border: none;
    font-size: 10px;
    text-transform: capitalize;
}
.environmental_vt input[type='date'] {
    width: 68%;
    font-size: 12px;
    margin-bottom: 10px;
}
.data_pla_vt {
    width: 100%;
    float: right;
    margin-top: 0;
    // position: absolute;
    left: 0;
    top: -28px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.data_pla_vt ul li {
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    font-family: 'Sofia-Pro-Regular-Az ,sans-serif';
    font-size: 9px;
    margin: 0px 5px 0 0px;
    color: #bbb8b8;
}
.blue3_vt {
    background-color: #0a9405;
    min-width: 10px;
    max-width: 10px;
    height: 10px;
    border-radius: 2px;
    float: left;
    margin-right: 3px;
}
.data_pla_vt ul li strong {
    font-size: 12px;
    font-weight: 300;
    color: #636363;
    padding-left: 2px;
}
.blue4_vt {
    background-color: #f82c1c;
    min-width: 10px;
    max-width: 10px;
    height: 10px;
    border-radius: 2px;
    float: left;
    margin-right: 3px;
}
.data_pla_vt ul li strong {
    font-size: 12px;
    font-weight: 300;
    color: #636363;
    padding-left: 2px;
}

.data_pla_vt ul {
    margin: 0;
    padding: 0;
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#exTab1 .tab-content {
    color: white;
    background-color: #428bca;
    padding: 5px 15px;
}

#exTab2 h3 {
    color: white;
    background-color: #428bca;
    padding: 5px 15px;
}
#exTab1 .nav-pills > li > a {
    border-radius: 0;
}
#exTab3 .nav-pills > li > a {
    border-radius: 4px 4px 0 0;
}
#exTab3 .tab-content {
    color: white;
    background-color: #fa6264;
    padding: 5px 15px;
}
.pvValues {
    width: 100% !important;
}
.pvValues td {
    padding: 5px 7px 20px 7px;
    font-size: 11px;
}
.pvValues th {
    color: #d38527;
    padding: 5px 7px 20px 7px;
}
::ng-deep.slick-prev {
    left: -2px !important;
    z-index: 11 !important;
}
::ng-deep.slick-next {
    right: 0px !important;
}
.plantTabouter {
    background-color: #fa6264;
    width: 1051px !important;
    height: 100%;
    margin-bottom: 30px;
    padding: 15px 10px;
    color: #fff;
    .plantTabsList {
        width: 100%;
        height: auto;
        display: block;
        position: relative;
        // display: flex;
        span {
            width: 100%;
            height: 100%;
            display: block;
            img {
                width: 100%;
                height: 100%;
                display: block;
            }
        }

        .vault-value {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            .valut-value-one {
                width: 100px;
                position: absolute;
                top: 52%;
                left: 21%;
                line-height: 12px;

                font-size: 14px;
                color: #fff;
                z-index: 99;
                text-align: center;
                width: auto;
                span {
                    color: #fff;
                }
            }
            .valut-value-two {
                width: 100px;
                position: absolute;
                top: 30%;
                left: 46%;
                line-height: 12px;

                font-size: 14px;
                color: #fff;
                z-index: 99;
                text-align: center;
                width: auto;
                span {
                    color: #fff;
                }
            }
            .valut-value-three {
                width: 100px;
                position: absolute;
                top: 83%;
                left: 48%;
                line-height: 12px;

                font-size: 14px;
                color: #fff;
                z-index: 99;
                text-align: center;
                width: auto;
                span {
                    color: #fff;
                }
            }
            .valut-value-four {
                width: 100px;
                position: absolute;
                top: 52%;
                left: 74%;
                line-height: 12px;

                font-size: 14px;
                color: #fff;
                z-index: 99;
                text-align: center;
                width: auto;
                span {
                    color: #fff;
                }
            }
        }
    }
}
.plantTabouterInverter {
    background-color: #FA6264;
    // width: 900px !important;
    height: 100%;
    margin-bottom: 0px;
    padding: 0px 10px 0px 10px;
    color: #fff;
    .plantTabsList {
        width: 100%;
        height: auto;
        display: block;
        position: relative;
        display: flex;
        span {
            width: 100%;
            height: 100%;
            display: block;
            img {
                width: 100%;
                height: 100%;
                display: block;
            }
        }

        .vault-value {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            .valut-value-one {
                width: 100px;
                position: absolute;
                top: 52%;
                left: 21%;
                line-height: 12px;

                font-size: 14px;
                color: #fff;
                z-index: 99;
                text-align: center;
                width: auto;
                span {
                    color: #fff;
                }
            }
            .valut-value-two {
                width: 100px;
                position: absolute;
                top: 30%;
                left: 46%;
                line-height: 12px;

                font-size: 14px;
                color: #fff;
                z-index: 99;
                text-align: center;
                width: auto;
                span {
                    color: #fff;
                }
            }
            .valut-value-three {
                width: 100px;
                position: absolute;
                top: 83%;
                left: 48%;
                line-height: 12px;

                font-size: 14px;
                color: #fff;
                z-index: 99;
                text-align: center;
                width: auto;
                span {
                    color: #fff;
                }
            }
            .valut-value-four {
                width: 100px;
                position: absolute;
                top: 52%;
                left: 74%;
                line-height: 12px;

                font-size: 14px;
                color: #fff;
                z-index: 99;
                text-align: center;
                width: auto;
                span {
                    color: #fff;
                }
            }
        }
    }
}
.graphicalview {
    position: absolute;
    top: 0px;
}
.graph_to_vt {
    width: 158px;
    height: 158px;
    float: none;
    border: 5px solid #fff;
    border-radius: 2px;
    padding: 10px;
    margin: 15px auto 0 auto;
    background: #b44244;
    overflow: hidden;
    border-radius: 50%;
}
.tol_area_vt {
    width: 131px;
    height: 131px;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    flex-direction: column;
}
.tol_area_vt h1 {
    font-size: 53px;
    font-weight: bold;
}
.tol_area_vt h4 {
    font-size: 18px;
}
.tol_area_vt h6 {
    font-size: 14px;
}
.tol_area_vt h4,
h1,
h6 {
    padding: 0;
    margin: 0 0 15px 0;
    color: #fff;
}
.tol_area_vt h4,
h1,
h6 {
    padding: 0;
    margin: 0 0 15px 0;
    color: #fff;
}
.outerInverter {
    background-color: #FA6264;
    table {
        tr {
            td {
                padding: 6px 4px !important;
                color: #fff;
            }
        }
        th {
            td {
                padding: 6px 4px !important;
                color: #fff;
            }
        }
    }
}

.inverterMenu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    a {
        color: #dfe4e8 !important;
        font-size: 16px;
        font-family: 'Sofia-Pro-Semi-Bold-Az ,sans-serif';
        font-weight: 500;
        min-height: auto;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: auto;
        padding: 10px;
        cursor: pointer;
       width: auto;
        font-size: 15px;
        // background-color: #f39c12;
        margin: auto;
        background: #df4c4e !important;
    border-radius: 0px;
    color: #fff !important;

        span {
            // transform: rotate(-90deg);
            min-width: 150px;
            height: auto !important;
        }
    }
}

.gview{
    a{
       background-color: transparent !important;
     }
}
.pvGraphTabCarousel{
     a{
        background-color: transparent !important;
     }
}
.emiGraph {
    width: 100%;
    height: 424px;
    display: flex;
    justify-content: center;
    align-items: center;
    h5 {
        font-size: 30px;
        color: #fff;    }
}
.loading {
    position: absolute;
    left: 47%;
    width: 50px;
    top: 43%;
}
.plantTabOuter {
    .col-md-3 {
        border: 1px solid #FA6264;
        padding: 0px;
    }
}
.inverterData {
    max-width: 100%;
    position: relative;
    height: 424px;
}
.inverterData {
    table {
        td {
            font-size: 11px;
        }
    }
}
.menu_unt {
    ul {
        li {
            h4 {
                font-size: 11px;
            }
            h5 {
                font-size: 11px;
            }
        }
    }
    font-size: 12px;
}


.Generation_text {
    position: absolute;
    left: 0px;
    bottom: -115px;
    font-size: 14px;
    color: #fff;
    z-index: 99;
    text-align: center;
    width: auto;
}
.meter_text {
    position: absolute;
    left: 26px;
    bottom: -143px;
    font-size: 14px;
    color: #fff;
    z-index: 99;
    text-align: center;
    width: auto;
}
.Grid_text {
    position: absolute;
    right: 22px;
    bottom: -118px;
    font-size: 14px;
    color: #fff;
    z-index: 99;
    text-align: center;
    width: auto;
}

.single_dashboard_vt {
    width: 100%;
    float: left;
    min-height: 407px;

    padding: 0;
    position: relative;
    overflow: hidden;
    max-height: 322px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}
.animated-button1 {
    display: inline-block;
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    overflow: hidden;
    // width: 10px;
    height: 80px;
    margin-top: 100px;
}


.btn_Consumption {
    width: 101px;
    height: 101px;
    position: absolute;
    /* overflow: hidden; */
    left: 225px;
    bottom: -160px;
    z-index: 999;
}

.btn_Consumption img {
    width: 100%;
}

.btn_Generation {
    width: 101px;
    height: 101px;
    position: absolute;
    /* overflow: hidden; */
    left: -47px;
    bottom: 62px;
}

.btn_Generation img {
    width: 100%;
}

.btn_Grid {
    width: 101px;
    height: 101px;
    position: absolute;
    /* overflow: hidden; */
    bottom: 61px;
    right: -47px;
}

.btn_Grid img {
    width: 100%;
}

.btn_meter {
    width: 101px;
    height: 101px;
    position: absolute;
    /* overflow: hidden; */
    left: 227px;
    bottom: -44px;
}

.btn_meter img {
    width: 100%;
}

.animation_soler_vt {
    width: 556px;
    min-width: 556px;
    max-width: 556px;
    position: relative;
    height: 184px;
    margin: -224px auto 0 auto !important;
}

#arrowAnim {
    width: 261px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: -9px;
    overflow: hidden;
}
#arrowright {
    width: 261px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: -9px;
    overflow: hidden;
}
#arrowbottom {
    width: 80px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -36px;
    bottom: 35px;
    overflow: hidden;
    overflow: hidden;
    transform: rotate(-90deg);
}
#arrowbottomtoo {
    width: 80px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -38px;
    bottom: 35px;
    overflow: hidden;
    overflow: hidden;
    transform: rotate(90deg);
}
#arrowbottomtop {
    width: 80px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 235px;
    bottom: -60px;
    overflow: hidden;
    overflow: hidden;
    transform: rotate(90deg);
}
#gridtohome {
    width: 80px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -38px;
    bottom: 25px;
    overflow: hidden;
    overflow: hidden;
    transform: rotate(90deg);
}
#gridtohomeleft {
    width: 261px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: -9px;
    overflow: hidden;
    transform: rotate(180deg);
}
.arrow {
    width: 8px;
    height: 8px;
    border: 1px solid;
    border-color: transparent #fff #fff transparent;
    transform: rotate(-45deg);
}

.arrowSliding {
    position: absolute;
    -webkit-animation: slide 4s linear infinite;
    animation: slide 4s linear infinite;
}

.delay1 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.delay2 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.delay3 {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

@-webkit-keyframes slide {
    100% {
        opacity: 0;
        transform: translateX(15vw);
    }
    80% {
        opacity: 1;
        transform: translateX(9vw);
    }
    20% {
        opacity: 1;
        transform: translateX(-9vw);
    }
    0% {
        opacity: 0;
        transform: translateX(-15vw);
    }
}

@keyframes slide {
    100% {
        opacity: 0;
        transform: translateX(15vw);
    }
    80% {
        opacity: 1;
        transform: translateX(9vw);
    }
    20% {
        opacity: 1;
        transform: translateX(-9vw);
    }
    0% {
        opacity: 0;
        transform: translateX(-15vw);
    }
}
#stop_all {
    display: none;
}
.plantTab {
    cursor: pointer;
}

.activeTab {
    background-color: #fa6264;
    color: #fff;

    height: 100%;
    h5{
        color: #fff;
    }
}
.sideTab {
    background-color: #fa6264 !important;
}
.drop_search_vt {
    float: left;
    margin-left: 6rem;
    position: relative;
    width: 220px;
    margin-top: 23px;
}
.inverterMenu-viewGraph {
    position: absolute;
    position: fixed;
    top: -5%;
    left: 2%;
    z-index: 1004;
}
::ng-deep.plantTabouter {
    .slick-list {
        padding: 5% 0px 0px 0px !important;
    }
}
@media (min-width: 1600px) and (max-width: 1921px) {
    .plantTabouter {
        background-color: #fa6264;
        width: 1600px !important;
        height: 100%;
        margin-bottom: 30px;
        padding: 48px 10px;
        color: #fff;
    }

    .plantTabsList {
        width: 1562px  !important;
    }
}
::ng-deep.dayWiseGraph {
    .highcharts-background {
        fill: #FFFFFF !important;
    }
}


/* New Element Styles abid bhai */


/* New Element Styles */
.table-main {
    .table-data {
        tr {
            &:not(:last-child) {
                border-bottom: 0;
            }
        }

        th {
            height: 56px;
            font-size: 13px;
        }

        td {
            font-size: 12px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        .ng-select {
            &.ng-select-single {
                .ng-select-container {
                    height: 30px !important;
                    font-size: 12px !important;
                    filter: drop-shadow(0px 2px 2px rgba(0,0,0,0.08));
                }

                .ng-arrow-wrapper {
                    width: 26px;
                }
            }
        }

        .editcategory,
        .deletecategory {
            color: #6d6d6d;
            background: transparent;
            margin: 0 5px;
            transition: 0.3s;

            &:hover {
                color: #FB8C04;
            }
        }
    }
}

.block-topbar {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 3px;
    padding-right: 3px;

    .btn {
        font-size: 14px;
        font-weight: 500;
        height: 34px;
        padding: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        &.btn-orange {
            min-width: 130px;

            &:hover {
                background: #ff4500 !important;
            }
        }

        &.btn-label {
            .label-icon {
                display: none;
            }
        }
    }

    .userSearcForm {
        position: relative;

        .form-control {
            width: 300px;
            font-size: 12px !important;
            line-height: 16px !important;
            height: 34px !important;
            padding: 7px 12px !important;

            &::placeholder {
                font-size: 12px !important;
            }
        }

        .search-icon {
            width: 34px;
            height: 34px;
            background: #FFBF00;
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 4px 4px 0;
            cursor: pointer;
        }
    }
}

.ng-select {
    &.ng-select-single {
        .ng-select-container {
            height: 40px !important;
            border: 1px solid #eee !important;
            border-radius: 2px !important;
            background: #fff !important;
            font-size: 14px !important;
            line-height: 20px !important;

            .ng-input {
                bottom: 0;
                padding-right: 35px !important;

                > input {
                    height: 100%;
                }
            }

            .ng-value {
                width: calc(100% - 40px);

                .ng-value-label {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .ng-arrow-wrapper {
            width: 32px;
            height: 100%;
            display: flex !important;
            align-items: center;
            justify-content: center;
            background: #ffbf00 !important;
            padding: 0;

            .ng-arrow {
                width: 8px;
                height: 8px;
                border: 0;
                border: 1px solid #fff;
                transform: rotate(45deg);
                border-top: 0;
                border-left: 0;
                margin-top: -3px;
                top: 0 !important;
            }
        }

        &.ng-select-opened {
            > .ng-select-container {
                .ng-arrow {
                    transform: rotate(225deg);
                    margin-top: 4px;
                }
            }
        }
    }
}

.form-styles {
    label {
        font-size: 13px !important;
        display: inline-block;
        margin: 0 0 5px;
    }

    .form-control {
        height: 40px !important;
        border: 1px solid rgba(109, 109, 109, 0.25) !important;
        border-radius: 4px !important;
        background: #fff !important;
        font-size: 14px !important;
        line-height: 20px !important;
        padding: 9px 12px !important;
        filter: drop-shadow(0px 2px 2px rgba(0,0,0,0.08 ));

        &::placeholder {
            font-size: 14px;
        }
        &:disabled, &[readonly]{
            background-color: #e9ecef !important;
        }
    }
        

    textarea {
        &.form-control {
            height: 160px !important;
            resize: none !important;
        }
    }

    .ng-select.ng-select-single .ng-select-container {
        border-radius: 4px !important;
        border-color: rgba(109, 109, 109, 0.25) !important;
        filter: drop-shadow(0px 2px 2px rgba(0,0,0,0.08 ));
    }

    .btn {
        height: 34px !important;
    }
}

.custom-checkbox {
    display: inline-flex !important;
    align-items: center;
    position: relative;

    input[type="checkbox"] {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        visibility: hidden;

        &:disabled {
            + .checkbox-item {
                background: #eee;
                border-color: #eee;
            }
        }

        &:checked {
            + .checkbox-item {
                background: orange;
                
                &:before {
                    display: block;
                }
            }
        }
    }

    .checkbox-item {
        width: 14px;
        height: 14px;
        display: block;
        margin: 0 7px 0 0;
        flex-shrink: 0;
        border-radius: 3px;
        border: 1px solid orange;
        position: relative;

        @media (max-width: 1599px) {
            margin: 0 5px 0 0;
        }

        &:before {
            position: absolute;
            left: 50%;
            top: 50%;
            content: '';
            width: 8px;
            height: 4px;
            border: 1px solid white;
            border-top: 0;
            border-right: 0;
            transform: rotate(-45deg);
            margin: -3px 0 0 -4px;
            display: none;
        }
    }

    .lbl-text {
        width: calc(100% - 20px);
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 11px;

        @media (max-width: 1599px) {
            font-size: 9px;
        }
    }

    &.large {
        .checkbox-item {
            width: 20px;
            height: 20px;

            &:before {
                width: 10px;
                height: 6px;
                border-width: 2px;
                margin: -4px 0 0 -5px;
            }
        }
    }
}

.inner-tab-data {
    .custom-checkbox {
        width: 100%;
    }

    .form-group {
        .col-1 {
            padding-right: 0 !important;

            @media (max-width: 1599px) {
                padding-left: 7px;
            }
        }
    }
}

.graph-block {
    canvas {
        width: 100% !important;
        height: 600px !important;
    }
}

.status-circle-online,
.status-circle-faulty,
.status-circle-offline {
    width: 41px;
    height: 41px;
    color: #fff;
    font-size: 12px;
    line-height: 1.35;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 100%;
    border-width: 4px;
    border-style: solid;

    @media (max-width: 1599px) {
        width: 34px;
        height: 34px;
        padding: 3px;
        border-width: 3px;
    }
}

.status-circle-online {
    background: #00d54e;
    border-color: #bbffd4;
}

.status-circle-offline {
    background: #848177;
    border-color: #d5d5d5;
}

.status-circle-faulty {
    background: #e9093f;
    border-color: #ffbccc;
}

.cursor-pointer {
    cursor: pointer;
    display: inline-block !important;
}

.modal-dialog {
    max-width: 600px !important;
}

.modal-header {
    background: #fb8c04 !important;

    .modal-title {
        text-transform: uppercase;
    }

    .btn-close {
        padding: 5px 15px;
        opacity: 1;
    }
}

.modal-body {
    .form-styles {
        label {
            margin-bottom: 10px;
        }
    }
}

.modal-footer {
    &.no-bg {
        background: #fff !important;
    }

    .btn {
        min-width: 130px;

        &.btn-outline-dark {
            border-color: transparent;
            box-shadow: 0px 2px 2px rgba(0,0,0,0.16);
        }

        &.btn-orange {
            &:hover {
                background: #ff4500 !important;
            }
        }
    }
}

.complainModelClass {
    .modal-body {
        padding-left: 25px;
        padding-right: 25px;
    }

    .table {
        margin: 0 !important;

        .thead-dark {
            th {
                background: #6d6d6d !important;
                border-color: #6d6d6d !important;
            }
        }

        tr {
            border: 0 !important;
        }

        th {
            font-size: 14px !important;
            padding-top: 5px !important;
            padding-bottom: 5px !important;
        }
        
        td {
            font-size: 12px !important;
            height: auto !important;
            border: 0 !important;
            padding-top: 25px !important;
            padding-bottom: 25px !important;
        }
    }
}

.chat-block {
    display: flex;
    flex-direction: column;

    .title {
        display: block;
        font-size: 12px;
        line-height: 1.5;
        color: #161616;
        font-weight: 500;
        margin: 0 0 10px;
    }
}

.messages-holder {
    max-height: 550px;
    min-height: 100px;
    // max-height: 400px;
    overflow: auto;
    border: 1px solid #dfe2e8;
    border-radius: 7px;
    margin: 0 0 20px;
    padding: 20px;
    .day-separator {
        width: 100%;
        text-align: center;
        position: relative;

        &:before {
            width: 100%;
            height: 1px;
            background: #ebedf1;
            position: absolute;
            left: 0;
            top: 51%;
            transform: translateY(-50%);
            content: '';
        }

        span {
            background: #fff;
            font-size: 12px;
            color: #828ca4;
            font-weight: 500;
            position: relative;
            z-index: 1;
            padding: 5px 30px;
        }
    }
}

.message-box {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 1.5;

    .avatar {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        overflow: hidden;
        flex-shrink: 0;
        margin: 25px 20px 0 0;
        border: 1px solid #dfe2e8;

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            object-position: center;
        }
    }

    .message-item {
        flex-grow: 1;
        min-width: 0;
    }

    .message-wrap {
        margin: 0 0 15px;
        display: flex;
    }

    .name {
        display: block;
        font-weight: 600;
        color: #828ca4;
        margin: 0 0 5px;
    }

    .text-message {
        display: inline-flex;
        align-items: center;
        flex-direction: column;
    }

    .text-options-wrap {
        display: flex;
        align-items: center;
    }

    .text {
        color: #fff;
        padding: 15px;
        background: #fb8c04;
        border: 1px solid #fb8c04;
        border-radius: 0 10px 10px 10px;
        box-shadow: 2px 5px 15px rgba(146, 200, 255, 0.55);
        width: calc(100% - 50px);
        max-width: 520px;
    }

    .option-dots {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;

        .dots {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 0;
            background: transparent;
            padding: 0;

            &:before,
            &:after,
            span {
                width: 4px;
                height: 4px;
                border-radius: 100%;
                background: #b3bbcb;
                content: '';
                transition: 0.3s;
            }
            
            &:hover {
                &:before,
                &:after,
                span {
                    background: #000;
                }
            }
        }
    }

    .message-time {
        width: 100%;
        text-align: left;
        padding-right: 48px;
        margin: 5px 0 0;
        // margin: 10px 0 0;
        font-size: 12px;
        color: #828ca4;
        font-weight: 500;

        &:empty {
            margin: 0;
        }
    }

    .file-option {
        display: flex;
        align-items: center;
        padding: 5px 10px;

        .file-icon {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            background: #fca943;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            flex-shrink: 0;
        }

        .file-info {
            flex-grow: 1;
            min-width: 0;
            padding-left: 20px;
        }

        .file-name {
            display: block;
            font-weight: 500;
            margin: 0 0 5px;
        }

        .file-weight {
            display: block;
            font-size: 13px;
            opacity: 0.7;
        }
    }

    .image-option,
    .video-option {
        min-width: 230px;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .video-option {
        .video-icon {
            width: 36px;
            height: 36px;
            background: #fff;
            border-radius: 100%;
            color: #fb8c04;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &.reverse {
        flex-direction: row-reverse;

        .name {
            text-align: right;
        }

        .avatar {
            margin-left: 20px;
            margin-right: 0;
        }

        .message-wrap {
            justify-content: flex-end;
        }

        .text-options-wrap {
            flex-direction: row-reverse;
        }

        .text {
            color: #7a859e;
            background: #fff;
            border-color: #dfe2e8;
            border-radius: 10px 10px 0 10px;
            box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.1);
        }

        .option-dots {
            margin-left: 0;
            margin-right: 14px;
        }

        .message-time {
            padding-left: 48px;
            padding-right: 0;
            text-align: right;
        }

        .file-option {    
            .file-icon {
                background: #f16111;
            }
        }

        .image-option {
            color: #fb8c04;
        }

        .video-option {
            .video-icon {
                background: #f16111;
                color: #fff;
            }
        }
    }
}

.messages-form {
    border: 1px solid #dfe2e8;
    border-radius: 7px;
    padding: 20px 10px;
    display: flex;
    align-items: center;

    .fileUpload-box {
        width: 40px;
        height: 40px;
        position: relative;
        flex-shrink: 0;

        &:hover {
            .filelist-option {
                opacity: 1;
                visibility: visible;
                max-height: 200px;
            }
        }
    }

    .message-form-options {
        flex-shrink: 0;
        display: flex;
        align-items: center;
    }

    .message-input {
        flex-grow: 1;
        min-width: 0;

        textarea {
            width: 100%;
            height: 40px;
            font-size: 14px;
            line-height: 20px;
            color: #000;
            border: 0;
            resize: none;
            padding: 10px 25px;

            &:focus {
                outline: none;
            }
        }
    }

    .add-button,
    .emoji-button,
    .mic-button,
    .send-button,
    .option-button {
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border: 0;
        background: transparent;
        margin: 0 7px;
        font-size: 0;
        line-height: 0;
        padding: 5px;
        color: #000;
        box-shadow: 2px 5px 13px rgba(146, 200, 255, 0.75);
        transition: 0.3s;


        svg {
            transition: 0.3s;
        }
    }

    .add-button,
    .send-button {
        color: #fff;
        background: linear-gradient(135deg,  #f98707 0%,#f16111 100%);

        &:hover {
            background: linear-gradient(135deg,  #f16111 0%,#f98707 100%);
        }
    }

    .mic-button,
    .emoji-button {
        svg {
            opacity: 0.5;
        }

        &:hover {
            svg {
                opacity: 1;
            }
        }
    }

    .emoji-button {
        box-shadow: none;
    }

    .add-button {
        margin: 0;
        position: relative;
        z-index: 1;
    }

    .send-button {
        padding-top: 7px;
        padding-right: 7px;
    }
}

.filelist-option {
    list-style: none;
    padding: 0 0 5px;
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: all 0.5s ease-in-out;

    li {
        width: 100%;
        padding: 4px 0;

        .option-button {
            color: #fff;
            margin: 0;
            background: #939393;
            box-shadow: none;

            &:hover {
                background: #fb8c04;
            }
        }
    }
}

.ngx-datatable {
    &.material {
        .datatable-header {
            height: 56px !important;
            border: 0 !important;

            .datatable-header-cell {
                padding: 10px 13px !important;
                height: 56px;
                background: #FB8C04 !important;
                color: #fff;
                font-size: 13px !important;
                line-height: 17px !important;
                text-transform: uppercase !important;

                @media(max-width: 1599px) {
                    font-size: 11px !important;
                    line-height: 14px !important;
                    padding: 7px 10px !important;
                }

                .datatable-header-cell-template-wrap {
                    height: 100%;
                    display: flex;
                    align-items: center;
                }

                .resize-handle {
                    display: none !important;
                }
            }
        }

        .datatable-body {
            .datatable-body-row {
                .datatable-body-cell {
                    display: inline-flex;
                    align-items: center;
                    color: #161616 !important;
                    font-size: 12px;
                    padding: 10px 13px !important;
                    line-height: 17px;
                    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;

                    @media(max-width: 1599px) {
                        font-size: 11px !important;
                        padding: 7px 10px !important;
                    }

                    .form-group {
                        &:empty {
                            padding: 0 !important;
                        }
                    }

                    .badge {
                        border-radius: 30px;
                        font-size: 11px !important;
                        font-weight: 500;
                        min-width: 84px;

                        &.badge-success {
                            background: #009200;
                        }

                        &.badge-secondary {
                            background: #cc2300;
                        }
                    }

                    .text-primary {
                        color: #6d6d6d !important;
                        transition: 0.3s;

                        &:hover {
                            color: #FB8C04 !important;
                        }
                    }

                    .datatable-row-detail {
                        &:empty {
                            padding: 0 !important;
                        }
                    }
                }
            }
        }

        .datatable-footer {
            border: 0 !important;
            margin-top: 30px !important;

            .datatable-footer-inner {
                padding-bottom: 10px;
            }

            .datatable-pager {
                .pager {
                    li {
                        a {
                            transition: 0.3s;
                        }

                        &.pages {
                            a {
                                height: 26px;
                                min-width: 26px;
                                line-height: 26px;
                                background: #f1f5f9;

                                &:hover {
                                    color: #fff;
                                    background: #ffbf00;
                                }
                            }

                            &.active {
                                a {
                                    color: #fff;
                                    background: #ffbf00;
                                }
                            }
                        }
                    }
                }
            }
        }

        &:not(.cell-selection) {
            .datatable-body-row:hover,
            .datatable-body-row:hover .datatable-row-group {
                background: transparent !important;
            }
        }
    }
}

.userEditForm {
    overflow: hidden;
    padding: 10px 0 0;

    .user-image {
        width: 74px;
        height: 74px;
        overflow: hidden;
        border-radius: 100%;
        margin: 0 auto 25px;

        input{
            position: absolute;
            opacity: 0;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &.form-styles {
        label {
            font-weight: 700;
            font-size: 14px !important;
        }

        .form-control {
            color: #000;
            font-weight: 400;
        }

        textarea.form-control {
            height: 135px;
        }
    }
}

.changePasswordForm {
    &.form-styles {
        .form-group {
            padding-bottom: 0 !important;
        }

        label {
            margin: 0;
        }
        .fieldTypeIcon {
            height: auto !important;
            border-radius: 4px;
            margin-left: 5px;

            span {
                left: 50% !important;
                top: 50% !important;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.donut-chart-label {
    &.addition {
        position: absolute;
        left: 50%;
        top: 50%;
        right: auto;
        transform: translate(-50%, -50%);
    }
}

.loader {
    z-index: 999;
    width: 100px;
    height: 100px;
    border: 3px dotted #fff;
    border-style: solid solid dotted dotted;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px dotted #FF3D00;
    border-style: solid solid dotted;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    animation: rotationBack 1s linear infinite;
    transform-origin: center center;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }

  .loaderwrapper
  {
    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
        // left: 300px;
    }
  }
// .ngx-charts-outer,
// .ngx-charts {
//     width: 100% !important;
//     height: 160px !important;
// }

.graph-area {
    overflow: visible !important;
}

.flow-grap-content {
    display: flex;
    align-items: center;
    padding: 32px 0;

    .flow-graph-left,
    .flow-grah-right {
        width: 50%;
    }

    .flow-graph-left {
        border: 1px solid #bbb;
        border-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 202px;
    }


    .ico-holder {
        position: relative;
        max-width: 64px;

        .title{
            position: absolute;
            bottom: -32px;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 12px;
            font-weight: 500;
        }
    }

    .flow-box {
        position: relative;

        &.one {
            .ico-holder {
                margin-top: -32px;
            }
        }

        &.two {
            .ico-holder {
                margin-bottom: -32px;
            }

            .arrow {
                bottom: -11px;
                top: inherit;
            }

            .text-box{
                bottom: -32px;
                top: auto !important
            }
        }

        &.three {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #bbb;
            }

            .ico-holder {

                &:first-child {
                    margin-left: -32px;
                }
            }

            .text-box {
                top: auto !important;
                bottom: 0;
                left: 50% !important;
            }

            .arrow {
                top: inherit;
            }
        }

        .text-box {
            top: 12px !important;
            left: calc(50% + 32px) !important;
            transform: translateX(-50%);

            .text-12 {
                font-weight: 500;
                font-size: 11px !important;
            }
        }

        .arrow {
            width: 22px;
            height: 22px;
            text-align: center;
            display: block;
            animation: arrow-animate 2s both infinite;
            position: absolute;
            top: -11px;
            left: 0;
            transform: none;

        }

        &.reverseable {
            .arrow {
                animation: arrow-animate2 2s both infinite;
                transform: rotate(180deg);
            }
        }

        &.no-animation {
            .arrow {
                display: none;
            }

            .ico-holder {
                filter: grayscale(1);
            }
        }
    }
}


@keyframes arrow-animate {
    0% {
        left: 0;
        opacity: 1
    }

    99% {
        opacity: 1
    }

    100% {
        left: calc(100% - 22px);
        opacity: 0;
    }
}

@keyframes arrow-animate2 {
    0% {
        left: calc(100% - 22px);
    }

    100% {
        left: 0;
    }
}


.ngx-datatable.material {
    .datatable-body {
        .datatable-body-row {
            .datatable-body-cell {
                min-height: 50px;

                .form-group {
                    &:only-child {
                        padding-bottom: 0 !important;
                    }
                }
            }
        }
    }

    + .table-meta {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.page-content {
    .container-fluid {
        @media (max-width: 1439px) {
            padding: 0;
        }
    }
}

.badge {
    &.small {
        font-size: 9px;
    }
}

/* New Updates */
.sidebar-menu-trigger {
    position: fixed;
    left: 20px;
    top: 23px;
    z-index: 1003;
    transition: left 0.3s ease-in-out;

    @media (min-width: 768px) {
        left: 330px;
    }

    .hamburger-inner, 
    .hamburger-inner::before, 
    .hamburger-inner::after {
        background: #FB8C04;
        transition: .15s ease;
    }
}


#app-menu-new {
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);

    @media (min-width: 768px) {
        transform: translateX(0);
    }
}

body:not(.sidebar-active) {
    .hamburger-inner {
       /* @media (min-width: 768px) {
            background: transparent;
        }

        &:before {
            @media (min-width: 768px) {
                transform: rotate(45deg);
                top: 0;
            }
        }

        &:after {
            @media (min-width: 768px) {
                transform: rotate(-45deg);
                bottom: 0;
            }
        }*/
    }
}

.sidebar-active {
    .sidebar-menu-trigger {
        left: 320px;
        
        @media (min-width: 768px) {
            left: 30px;
        }
    }

    .hamburger-inner {
        @media (max-width: 767px) {
            background: transparent;
        }
/*
        &:before {
            @media (max-width: 767px) {
                transform: rotate(45deg);
                top: 0;
            }
        }

        &:after {
            @media (max-width: 767px) {
                transform: rotate(-45deg);
                bottom: 0;
            }
        }*/
    }

    #app-menu-new {
        transform: translateX(0);

        @media (min-width: 768px) {
            transform: translateX(-100%);
        }
    }

    .main-content {
        @media (min-width: 768px) {
            width: 100%;
            margin: 0;
        }
    }

    #page-topbar, 
    .footer {
        @media (min-width: 768px) {
            left: 0;
        }
    }
}

@media (max-width: 767.98px) {
    [data-layout="vertical"] .app-menu {
        margin-left: 0;
    }
}

.modal-backdrop {
    z-index: 1054 !important;
}

.modal {
    pointer-events: none;
}

.ngx-datatable {
    &.material {
        box-shadow: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .datatable-header,
        .datatable-header-inner,
        .datatable-body,
        .datatable-scroll {
            width: 100% !important;
        }

        // .datatable-header-cell,
        // .datatable-body-cell  {
        //     width: max-content !important;
        // }

        .datatable-body-cell {
            overflow-x: visible;
        }

        .datatable-body-cell-label {
            width: 100%;
        }

        .priorityList {
            width: 100% !important;

            .ng-select {
                 .ng-select-container {
                    width: 100% !important;
                 }
            } 
        }
        
        .ng-select {
            &.ng-select-single {
                .ng-select-container {
                    height: 30px !important;
                    font-size: 12px !important;
                    filter: drop-shadow(0px 2px 2px rgba(0,0,0,0.08));

                    @media (max-width: 1599px) {
                        font-size: 11px !important;
                    }
                }

                .ng-arrow-wrapper {
                    width: 26px;
                }
            }
        }
    }
}

.alaramCleareButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background: #fb8c04;
    color: #fff;

    svg {
        width: 16px;
        height: 16px;
    }

    &:hover {
        color: #fff;
        background: #e37e02;
    }

    &.cross-btn {
        padding: 0;
        margin: 0;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        margin: 0 auto
    }
}

.show-entries-list {
    display: flex !important;
    align-items: center;
    padding: 5px 0 20px !important;
    margin: 0 !important;
    font-size: 14px !important;

    .form-control {
        min-width: 80px;
        width: auto !important;
        height: 38px !important;
        font-size: 14px !important;
        padding: 8px 12px !important;
        margin: 0 6px !important;
        border-color: #d9d9d9 !important;
        padding-bottom: 20px;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M12 18l-6-6h12z"/></svg>') !important;
        background-repeat: no-repeat !important;
        background-position: right center !important;
    }
}

.plant-details-table {
    &.table-main {
        .table-data {
            th,
            td {
                @media (max-width: 1599px) {
                    font-size: 11px;
                    padding: 10px 12px 10px 0 !important;
                }
            }
        }
    }
}

.plant-inverter-title {
    font-size: 20px;
    line-height: 1.3;
    font-weight: 600;
    margin: 0 0 20px;
    text-align: center;
    color:#EF4323;
}
.go-to-page {
    gap: 5px;
    padding: 0 15px;
    label{
      display: inherit;
      font-size: 12px;
      flex-shrink: 0;
  
    }
    .form-control{
      height: 26px !important;
      border-radius: 3px;
    }
    .btn{
      background-color: #ffbf00 !important;
      border-color: #ffbf00 !important;
    }
  }

  // third phase css
  .rates-month-holder{
    background: #fff5e6;
    padding: 1rem 1.5rem;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
    label{
        font-size: 14px;
    }
    input.form-control.form-control-sm {
        height: 42px;
        font-weight: 400;
    }
    &.view{
        background: transparent;
    }
    
}

.table-wrap .hstack a{
    color: #6d6d6d !important;
    transition: 0.3s;
    &:hover{
        color: #FB8C04 !important;
    }
}
.filter-row{
    align-items: center;
    padding: 0 0 17px;
    .show-entries-list{
        padding: 0 !important;
        .form-control{
            min-width: 60px;
        }
    }
    .form-group{
        padding: 0;
    }
    .ng-select.ng-select-single .ng-select-container {
        height: 34px !important;
    }
    .col-btn{
        flex-grow: 1;
        text-align: right;
    }
}

.filter-btn {
    padding-bottom: 1.5rem;

    .btn.btn-primary {
        background: #FFFFFF;
        border-color: #707070;
        min-width: clamp(7rem, 2vw, 11.25rem);
        min-height: clamp(1.8rem, 2vw, 2.5rem);
        color: #333334;
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        box-shadow: 0 2px 4px #000000;

        &.active, &:focus,
        &:hover {
            color: #fff;
            background: #FFBF00;
            border-color: #FFBF00 !important;
            box-shadow: 0 2px 4px #000000 !important;

        }

        // &:focus {
        //     color: #333334;
        //     border-color: #707070;
        //     background: #FFFFFF;
        // }

    }

}
.breadcrumb-main .breadcrumb {
    padding: 50px 30px 25px;
}
.contents-main {
    padding: 50px 16px 40px;
}
// notification styles
.notifications{
    .dropdown-toggle.notification-icon{
        &::after{
            display: none;
        }
    }
    
    .avatar {
        background-color: transparent;
        color: #ffffff;
        display: inline-block;
        font-weight: 500;
        height: 34px;
        line-height: 34px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        vertical-align: middle;
        width: 34px;
        position: relative;
        white-space: nowrap;
        margin: 0 10px 0 0;
        border-radius: 50%;
    }
    
    .avatar>img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
    .chat-block{
        flex-direction: row;
    }
    .topnav-dropdown-header {
        border-bottom: 1px solid #eeeeee;
        text-align: center;
        font-size: 12px;
        height: 32px;
        line-height: 32px;
        padding: 0 12px;
    }
    
    .topnav-dropdown-header .notification-title {
        color: #333333;
        display: block;
        float: left;
        font-size: 14px;
    }
    
    .topnav-dropdown-header .clear-noti {
        float: right;
        font-size: 11px;
        text-transform: uppercase;
        &:hover{
            color: #EF4323 !important;
                text-decoration: underline !important;
        }
    }
    
     .noti-content {
        height: 290px;
        // width: 450px;
        overflow-y: auto;
        position: relative;
    }
    .notification-list {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            margin-top: 0;
            position: relative;
            border-bottom: 1px solid #f5f5f5;
            padding: 16px !important;
            .noti-title {
                color: #333333;
            }
            .noti-details {
                color: #989c9e;
                margin: 0;
            }
            p.noti-time {
                margin: 0;
            }
            .notification-time {
                font-size: 12px;
                line-height: 1.35;
                color: #bdbdbd;
            }
        }
    }
    .new-noti {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #f99584;
        border: 1px solid #EF4323;
        display: block;
        position: absolute;
        top: 8px;
        left: 8px;
    }
}